<template>
  <chart :options="options" constructor-type="stockChart" ref="chart" />
</template>

<script>
const clonedeep = require('lodash.clonedeep')
import { Chart } from 'highcharts-vue'
import utils from './utils.js'

const OPTIONS = require('./chartOptions.js')
const SUFFIXES = [
  'PRICE_IDX',
  'OI_IDX',
  'BASIC_IDX',
  'FUNDING_RATE_IDX',
  'ACTIVE_OI_IDX',
]

export default {
  name: 'x-chart',

  components: {
    Chart,
  },

  data: () => ({
    data: {},
    options: null,
  }),

  created() {
    this.options = clonedeep(OPTIONS)
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      this.$refs.chart.chart.showLoading()
      this.data = await this.$http.get('/api/x-chart')
      this.setData()
      this.$refs.chart.chart.hideLoading()
    },
    setData() {
      const series = utils.generateSeries()
      Object.values(this.data).forEach((item) => {
        const timestamp = parseInt(item[utils.idx.TIMESTAMP_IDX])
        utils.EXCHANGE_PAIRS.forEach((prefix) => {
          SUFFIXES.forEach((suffix) => {
            utils.setSeriesData(this.data, series, timestamp, prefix, suffix)
          })
        })
      })
      this.options.series = Object.values(series)
    },
  },
}
</script>
