const BTC_PRICE_AXIS_INDEX = 0
const ETH_PRICE_AXIS_INDEX = 1
const BTC_OI_AXIS_INDEX = 2
const ETH_OI_AXIS_INDEX = 3
const BTC_ACTIVE_OI_AXIS_INDEX = 4
const ETH_ACTIVE_OI_AXIS_INDEX = 5
const BASIC_INDEX = 6
const FUNDING_RATE_INDEX = 7

const LENGTH = 41
const [
  TIMESTAMP_IDX,

  BITMEX_XBTUSD_PRICE_IDX,
  BINANCEF_BTCUSDT_PRICE_IDX,
  BYBIT_BTCUSD_PRICE_IDX,
  OKEX_BTCUSD_PRICE_IDX,
  HUOBI_BTCUSD_PRICE_IDX,
  FTX_BTCPREP_PRICE_IDX,
  BITMEX_ETHUSD_PRICE_IDX,
  BINANCEF_ETHUSDT_PRICE_IDX,

  BITMEX_XBTUSD_OI_IDX,
  BINANCEF_BTCUSDT_OI_IDX,
  BYBIT_BTCUSD_OI_IDX,
  OKEX_BTCUSD_OI_IDX,
  HUOBI_BTCUSD_OI_IDX,
  FTX_BTCPREP_OI_IDX,
  BITMEX_ETHUSD_OI_IDX,
  BINANCEF_ETHUSDT_OI_IDX,

  BITMEX_XBTUSD_BASIC_IDX,
  BINANCEF_BTCUSDT_BASIC_IDX,
  BYBIT_BTCUSD_BASIC_IDX,
  OKEX_BTCUSD_BASIC_IDX,
  HUOBI_BTCUSD_BASIC_IDX,
  FTX_BTCPREP_BASIC_IDX,
  BITMEX_ETHUSD_BASIC_IDX,
  BINANCEF_ETHUSDT_BASIC_IDX,

  BITMEX_XBTUSD_FUNDING_RATE_IDX,
  BINANCEF_BTCUSDT_FUNDING_RATE_IDX,
  BYBIT_BTCUSD_FUNDING_RATE_IDX,
  OKEX_BTCUSD_FUNDING_RATE_IDX,
  HUOBI_BTCUSD_FUNDING_RATE_IDX,
  FTX_BTCPREP_FUNDING_RATE_IDX,
  BITMEX_ETHUSD_FUNDING_RATE_IDX,
  BINANCEF_ETHUSDT_FUNDING_RATE_IDX,

  BITMEX_XBTUSD_ACTIVE_OI_IDX,
  BINANCEF_BTCUSDT_ACTIVE_OI_IDX,
  BYBIT_BTCUSD_ACTIVE_OI_IDX,
  OKEX_BTCUSD_ACTIVE_OI_IDX,
  HUOBI_BTCUSD_ACTIVE_OI_IDX,
  FTX_BTCPREP_ACTIVE_OI_IDX,
  BITMEX_ETHUSD_ACTIVE_OI_IDX,
  BINANCEF_ETHUSDT_ACTIVE_OI_IDX,
] = [...Array(LENGTH)].map((_, idx) => idx)

const COLORS = {
  DARK: {
    HUOBI_BTCUSD: '#9bc54a',
    OKEX_BTCUSD: '#eaba4c',
    FTX_BTCPREP: '#acbcec',
    BYBIT_BTCUSD: '#c47fcf',
    BINANCEF_BTCUSDT: '#d09855',
    BITMEX_XBTUSD: '#cf5664',
    BITMEX_ETHUSD: '#547ec0',
    BINANCEF_ETHUSDT: '#58af59',
  },
  LIGHT: {
    HUOBI_BTCUSD: '#bcd884',
    OKEX_BTCUSD: '#f0d084',
    FTX_BTCPREP: '#82d2f2',
    BYBIT_BTCUSD: '#d1a2d9',
    BINANCEF_BTCUSDT: '#deb88b',
    BITMEX_XBTUSD: '#de8e97',
    BITMEX_ETHUSD: '#8ca8d4',
    BINANCEF_ETHUSDT: '#8ec98f',
  },
}

const idx = {
  TIMESTAMP_IDX,

  BITMEX_XBTUSD_PRICE_IDX,
  BINANCEF_BTCUSDT_PRICE_IDX,
  BYBIT_BTCUSD_PRICE_IDX,
  OKEX_BTCUSD_PRICE_IDX,
  HUOBI_BTCUSD_PRICE_IDX,
  FTX_BTCPREP_PRICE_IDX,
  BITMEX_ETHUSD_PRICE_IDX,
  BINANCEF_ETHUSDT_PRICE_IDX,

  BITMEX_XBTUSD_OI_IDX,
  BINANCEF_BTCUSDT_OI_IDX,
  BYBIT_BTCUSD_OI_IDX,
  OKEX_BTCUSD_OI_IDX,
  HUOBI_BTCUSD_OI_IDX,
  FTX_BTCPREP_OI_IDX,
  BITMEX_ETHUSD_OI_IDX,
  BINANCEF_ETHUSDT_OI_IDX,

  BITMEX_XBTUSD_BASIC_IDX,
  BINANCEF_BTCUSDT_BASIC_IDX,
  BYBIT_BTCUSD_BASIC_IDX,
  OKEX_BTCUSD_BASIC_IDX,
  HUOBI_BTCUSD_BASIC_IDX,
  FTX_BTCPREP_BASIC_IDX,
  BITMEX_ETHUSD_BASIC_IDX,
  BINANCEF_ETHUSDT_BASIC_IDX,

  BITMEX_XBTUSD_FUNDING_RATE_IDX,
  BINANCEF_BTCUSDT_FUNDING_RATE_IDX,
  BYBIT_BTCUSD_FUNDING_RATE_IDX,
  OKEX_BTCUSD_FUNDING_RATE_IDX,
  HUOBI_BTCUSD_FUNDING_RATE_IDX,
  FTX_BTCPREP_FUNDING_RATE_IDX,
  BITMEX_ETHUSD_FUNDING_RATE_IDX,
  BINANCEF_ETHUSDT_FUNDING_RATE_IDX,

  BITMEX_XBTUSD_ACTIVE_OI_IDX,
  BINANCEF_BTCUSDT_ACTIVE_OI_IDX,
  BYBIT_BTCUSD_ACTIVE_OI_IDX,
  OKEX_BTCUSD_ACTIVE_OI_IDX,
  HUOBI_BTCUSD_ACTIVE_OI_IDX,
  FTX_BTCPREP_ACTIVE_OI_IDX,
  BITMEX_ETHUSD_ACTIVE_OI_IDX,
  BINANCEF_ETHUSDT_ACTIVE_OI_IDX,
}

const EXCHANGE_PAIRS = [
  'BINANCEF_ETHUSDT',
  'BITMEX_ETHUSD',
  'HUOBI_BTCUSD',
  'OKEX_BTCUSD',
  'FTX_BTCPREP',
  'BYBIT_BTCUSD',
  'BINANCEF_BTCUSDT',
  'BITMEX_XBTUSD',
]

function generateSeries () {
  const series = {}

  EXCHANGE_PAIRS.forEach((prefix) => {
    const isETH = _isETH(prefix)

    let key = `${prefix}_PRICE_IDX`
    series[key] = {
      name: `${prefix}`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' $',
      },
      yAxis: isETH ? ETH_PRICE_AXIS_INDEX : BTC_PRICE_AXIS_INDEX,
      data: [],
    }

    key = `${prefix}_OI_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - OI`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' $',
      },
      yAxis: isETH ? ETH_OI_AXIS_INDEX : BTC_OI_AXIS_INDEX,
      data: [],
      zones: [],
    }

    key = `${prefix}_ACTIVE_OI_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - Active OI`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' $',
      },
      yAxis: isETH ? ETH_ACTIVE_OI_AXIS_INDEX : BTC_ACTIVE_OI_AXIS_INDEX,
      data: [],
      zones: [],
    }

    key = `${prefix}_BASIC_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - Basic`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: ' %',
      },
      yAxis: BASIC_INDEX,
      data: [],
    }

    key = `${prefix}_FUNDING_RATE_IDX`
    series[key] = {
      type: 'area',
      stacking: 'normal',
      lineWidth: 0,
      color: COLORS.DARK[prefix],
      name: `${prefix} - Funding`,
      marker: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: ' %',
      },
      yAxis: FUNDING_RATE_INDEX,
      data: [],
    }
  })

  return series
}

function setSeriesData (data, series, timestamp, prefix, suffix) {
  const key = `${prefix}_${suffix}`
  const val = data[timestamp][idx[key]]
  series[key].data.push([timestamp, val])
}

function _isETH(pair) {
  return pair.includes('ETH')
}

module.exports = {
  idx,
  setSeriesData,
  generateSeries,
  EXCHANGE_PAIRS,
}
