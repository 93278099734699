<template>
  <div class="px-2 py-2" color="white">
    <x-chart />
  </div>
</template>

<script>
import XChart from '@/components/apps/market-information/x-chart/index.vue'

export default {
  name: 'market-information',

  meta: {
    title: 'X Chart',
  },

  components: {
    XChart,
  },
}
</script>
